/* @font-face {
  font-family: "Futura";
  font-style: "condensed";
  font-weight: "extra bold";
  src: url("../fonts/Futura Condensed Extra Bold.otf") format("truetype");
} */


.rdt_Table {
  margin-bottom: 15px;
}
.rdt_TableBody {
  overflow: auto;
  max-height: 75vh;
}

body {
  max-height: 100vh;
  overflow: auto;
}

.centered {
  width: 60%;
  margin: 0 auto;
  margin-top: 5%;
}
.centered form {
  width: 100%;
}

/* Tabla recipients*/
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
/*Tabla recipients*/