/* html {
    -webkit-tap-highlight-color: hsla(0,0,0,0);
            tap-highlight-color: hsla(0,0,0,0);
}
*/
.content {
    /* background-color: #363636; */
    background-color: #b6b2b2;
    /* background-image:         linear-gradient(45deg, hsla(0,0%,0%,.25) 25%, transparent 25%, transparent 75%, hsla(0,0%,0%,.25) 75%, hsla(0,0%,0%,.25)), 
                              linear-gradient(45deg, hsla(0,0%,0%,.25) 25%, transparent 25%, transparent 75%, hsla(0,0%,0%,.25) 75%, hsla(0,0%,0%,.25)); */
    background-image:         linear-gradient(45deg, hsla(0, 27%, 98%, 0.25) 25%, transparent 25%, transparent 75%, hsla(0, 27%, 98%, 0.25) 75%, hsla(0, 27%, 98%, 0.25)), 
                              linear-gradient(45deg, hsla(0, 27%, 98%, 0.25) 25%, transparent 25%, transparent 75%, hsla(0, 27%, 98%, 0.25) 75%, hsla(0, 27%, 98%, 0.25));
    background-position:0 0, 2px 2px;
    background-size:4px 4px;
    padding: 50px;
    border-radius: 20px;
    color: white;

} 
/* 
button {
    left: 50%;
    margin: -40px;
    position: absolute;
    top: 50%;
}
button:hover,
button:active {
    outline: 0;
}
 */
/* 3D Button */

button.depth {
    background: #444;
    border: none;
    border-radius: 80px;
    box-shadow: inset 0 0 2px 2px hsla(0,0%,0%,.2),
                inset 0 0 2px 4px hsla(0,0%,0%,.2),
                inset 0 0 2px 6px hsla(0,0%,0%,.2),
                inset 0 0 1px 8px hsla(0,0%,0%,.5),
                inset 0 -4px 2px 4px hsla(0,0%,0%,.5),
                inset 0 1px 1px 8px hsla(0,0%,100%,.25),
                inset 0 -30px 30px hsla(0,0%,0%,.2),
                0 -4px 8px 4px hsla(0,0%,0%,.5),
                0 10px 10px hsla(0,0%,0%,.25),
                0 0 2px 2px hsla(0,0%,0%,.2),
                0 0 2px 4px hsla(0,0%,0%,.2),
                0 0 2px 6px hsla(0,0%,0%,.2),
                0 0 2px 8px hsla(0,0%,0%,.5),
                0 1px 2px 8px hsla(0,0%,100%,.25),
                0 -1px 2px 8px hsla(0,0%,0%,.5);
    color: #303030;
    cursor: pointer;
    font: bold 40px/85px sans-serif;
    height: 80px;
    padding: 0;
    text-shadow: 0 1px 1px hsla(0,0%,100%,.25),
                 0 -1px 1px hsla(0,0%,0%,.75);
    width: 80px;
}

/* button.depth:hover,
button.depth:focus { */
button.active {
    color: #0ab;
    text-shadow: 0 0 20px hsla(240,75%,75%,.5),
                 0 1px 1px hsla(0,0%,100%,.25),
                 0 -1px 1px hsla(0,0%,0%,.75);
} 

button.depth:active {
    box-shadow: inset 0 0 2px 2px hsla(0,0%,0%,.2),
                inset 0 0 2px 4px hsla(0,0%,0%,.2),
                inset 0 0 2px 6px hsla(0,0%,0%,.2),
                inset 0 0 1px 7px hsla(0,0%,0%,.5),
                inset 0 5px 15px 7px hsla(0,0%,0%,.15),
                inset 0 -4px 2px 3px hsla(0,0%,0%,.5),
                inset 0 1px 1px 7px hsla(0,0%,100%,.25),
                inset 0 -30px 30px hsla(0,0%,0%,.1),
                inset 0 30px 30px hsla(0,0%,0%,.2),
                0 -4px 8px 4px hsla(0,0%,0%,.5),
                0 5px 10px hsla(0,0%,0%,.25),
                0 0 2px 2px hsla(0,0%,0%,.2),
                0 0 2px 4px hsla(0,0%,0%,.2),
                0 0 2px 6px hsla(0,0%,0%,.2),
                0 0 2px 8px hsla(0,0%,0%,.5),
                0 1px 2px 8px hsla(0,0%,100%,.25),
                0 -1px 2px 8px hsla(0,0%,0%,.5);
    line-height: 86px;
}